import * as React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import TitleBlock from "../components/titleBlockPages"
import StoryBlock from "../components/titleBlockPages"
import FullWidthCTA from "../components/fullWidthCTABlock"
import DesignDeliver from "../components/design-deliver"

import Testimonials from "../components/testimonials"
import ContactForm from "../components/footerContactBlock"

const WorkwearPage = ({ data }) => {
  ///page details
  const pageDataEdges = data.allPageworkwearJson.edges[0].node;
  const testimonialData = data.allTestimonialsworkwearJson.edges;
  const nodes = testimonialData.map((item) => { 
    return item.node;
  })
  return (
    <Layout>
      <Seo
        title="High Quality Customised Workwear To Keep Your People Safe"
        description="We can kit out your workers with ultra-tough, ultra-protective, precise spec customised workwear reflecting all the prestige of your brand. >>"
      />
      <TitleBlock
        pageTitle={pageDataEdges.pageTitle}
        pageSubTitle={pageDataEdges.pageSubTitle}
        pageTitleBody={pageDataEdges.pageTitleBody}
        img={pageDataEdges.pageTitleImg}
      />
      <FullWidthCTA
        lsH3={'Interested in workwear?'}
        rsH3={""}
      />
      <DesignDeliver sectionName="workwear" />
      {/*
      <CasestudyBlock
        sectionTitle = {'Case Study'}
        pageTitle={pageDataEdges.casestudyBlockTitle}
        pageSubTitle={pageDataEdges.casestudyBlockSubTitle}
        pageTitleBody={pageDataEdges.casestudyBlockBody}
        img={pageDataEdges.casestudyBlockImg}
      />
      */} 
      <Testimonials
        bg={"primary"}
        sectionTitleBlock={'What makes us different?'}
        sectionCopy={'More than just pens & polo shirts, we’re experts in brand expression & lasting impressions.'}
        nodes={nodes}
      />
        <StoryBlock
          sectionTitle = {'Sustainability'}
          pageTitle={pageDataEdges.storyBlockTitle}
          pageSubTitle={pageDataEdges.storyBlockSubTitle}
          pageTitleBody={pageDataEdges.storyBlockBody}
          img={pageDataEdges.storyBlockImg}
      />
      
      <ContactForm />
    </Layout>
  )
}
export const query = graphql`
  query MyQuery {
    
     allTestimonialsworkwearJson {
        edges {
        node {
            name
            copy
            img {
              childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
              }
              
            }
        }
        }
    }

    allPageworkwearJson {
      edges {
        node {
          pageTitleBody
          pageTitle
          pageSubTitle
          pageTitleImg {
                childImageSharp {
                    gatsbyImageData(
                      width: 1600
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
              }
          casestudyBlockBody
          casestudyBlockTitle
          casestudyBlockSubTitle
          casestudyBlockImg {
                childImageSharp {
                    gatsbyImageData(
                      width: 1600
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
              }
          storyBlockBody
          storyBlockTitle
          storyBlockSubTitle
          storyBlockImg {
                childImageSharp {
                    gatsbyImageData(
                      width: 1600
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
              }
        }
      }
    }
}`
export default WorkwearPage
